<template>
    <b-card no-body>
        <div class="m-2">
            <b-row>
                <b-col
                    cols="12"
                    md="6"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                    <label>List</label>
                    <v-select
                        v-model="perPage"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="perPageOptions"
                        :clearable="false"
                        class="per-page-selector d-inline-block ml-50 mr-1"
                    />
                    <b-button
                        variant="primary"
                        :to="{ name: 'roomType-add'}"
                    >
                        Add Room Type
                    </b-button>
                </b-col>
                <b-col cols="12" md="6">
                    <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                        v-model="filterRoomType"
                            class="d-inline-block mr-1"
                            placeholder="Search..."
                        />
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-table
            ref="refRoomTypeListTable"
            :items="fetchData"
            responsive
            striped
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
        >
            <template #cell(actions)="data">
                <span>
                    <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template v-slot:button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                        />
                        </template>
                        <b-dropdown-item :to="{ name: 'roomType-add', params: { id: data.item.id } }">
                        <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                        />
                        <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="deleteconfirm(data.item.id)">
                        <feather-icon
                            icon="TrashIcon"
                            class="mr-50"
                        />
                        <span>Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </span>
            </template>
        </b-table>
        <div class="mx-2 mb-2">
            <b-row>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRoomTypeList"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                        <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                        </template>
                        <template #next-text>
                            <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                            />
                        </template>
                    </b-pagination>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BImg, BFormSelect, BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import furnitureStoreModule from '../furnitureStoreModule'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BMedia, BAvatar,
        BLink, BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BImg, vSelect,
        BFormSelect, BFormGroup
    },

    setup() {
        const FURNITURE_APP_STORE_MODULE_NAME = "app-furniture";

        if (!store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
            store.registerModule(FURNITURE_APP_STORE_MODULE_NAME, furnitureStoreModule);
        }

        onUnmounted(() => {
            if (store.hasModule(FURNITURE_APP_STORE_MODULE_NAME)) {
                store.unregisterModule(FURNITURE_APP_STORE_MODULE_NAME);
            }
        });

        const toast = useToast();
        const tableColumns = ref([
            { key: 'id', label: '#', sortable: true },
            { key: 'name',label:'Room Type', sortable: false },
            { key: 'actions' },
        ])
        const perPageOptions = ref([10, 25, 50, 100])
        const perPage = ref(10)
        const sortBy = ref('id')
        const isSortDirDesc = ref(true)
        const filterRoomType = ref(null)
        const totalRoomTypeList = ref(0)
        const refRoomTypeListTable = ref(null)
        const currentPage = ref(1)
        const dataMeta = computed(() => {
            const localItemsCount = refRoomTypeListTable.value ? refRoomTypeListTable.value.localItems.length : 0
            return {
                from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
                to: perPage.value * (currentPage.value - 1) + localItemsCount,
                of: totalRoomTypeList.value,
            }
        })

        const fetchData = (ctx, callback) => {
            const offset = perPage.value*(currentPage.value-1);

            store
                .dispatch('app-furniture/getRoomTypeData', {
                    perpage: perPage.value,
                    offset: offset,
                    order_by: 'id DESC',
                    keywords: filterRoomType.value
                })
                .then((response) => {
                    totalRoomTypeList.value = response.data.total
                    callback(response.data.data)
                }).catch(error => {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error fetching Room type' list",
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                }
            )
        }

        watch([currentPage, perPage, filterRoomType], () => {
            refetchData()
        });

        const refetchData = () => {
            refRoomTypeListTable.value.refresh()
        }

        const deleteData = (id) => {
            store.dispatch('app-furniture/deleteRoomType', id).then(response => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Delete Success",
                        icon: 'AlertTriangleIcon',
                        variant: 'success',
                    },
                })

                refetchData();
            }).catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error Delete Room Type",
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
        }

        return {
            tableColumns,
            perPageOptions,
            perPage,
            filterRoomType,
            fetchData,
            sortBy,
            isSortDirDesc,
            dataMeta,
            totalRoomTypeList,
            refRoomTypeListTable,
            currentPage,
            deleteData,
        }
    },

    methods:{
        deleteconfirm(id){
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete Room Type ID.'+id, {
                    title: 'Please Confirm',
                    size: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Yes',
                    cancelTitle: 'No',
                    cancelVariant: 'outline-secondary',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(value => {
                    if(value === true){
                        this.deleteData(id)
                    }
                }
            )
        }
    },
}
</script>